@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Dosis&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;500;600;700&display=swap");
html {
  background-color:#151515;
}

body {
    overflow: auto;
    overscroll-behavior: none;
}

/* h1 {
  font-size: 32px;
  font-weight: 500;
} */

p {
  font-size: 18px;
  line-height: 30px;
}

    /* Apply styles to ::-moz-color-swatch in Firefox, and then other browsers */
input[type="color"]::-moz-color-swatch {
      border: none; /* Remove border */
      box-shadow: none; /* Remove shadow */
}
input[type="color"]::-webkit-color-swatch {
        border: none;
}